import Vue from 'vue';
import {mapMutations} from "vuex";
import SmallStats from "@/components/Shards/SmallStats";
import {get, post} from "@/services/api";
import {orderSoftware} from "@/services/endpoint.json";
import {getUrgencyName, userCan} from "@/utils";
import {ORDER_STATUS} from "@/constants";
import colors from '@/utils/colors';
import {decrypt, encrypt} from "@/helpers/encrypt";
import {UserRole} from "@/utils/auth.roles";
import show_alert_mixin from "@/utils/show_alert_mixin";


export default Vue.extend({
  name: 'Dashboard',
  components: {
    SmallStats
  },
  mixins: [show_alert_mixin],
  computed: {
    smallStats() {
      const arrayData = [{
        label: 'ORDER SOFTWARE',
        color: '#17c671',
        value: this.$store.state.counterInformation.data.totalNewOrder,
        percentage: '0%',
        increase: true,
        decrease: false,
        datasets: [{
          label: '',
          color: '#17c671',
          fill: 'start',
          borderWidth: 1.5,
          backgroundColor: colors.primary.toRGBA(0.1),
          borderColor: colors.primary.toRGBA(),
          data: [] // [9, 3, 3, 9, 9],
        }],
      }, {
        label: 'WAITING ORDER RESPONSE',
        color: '#007bff',
        value: this.$store.state.counterInformation.data.totalOrderWaitingResponse,
        percentage: '0%',
        increase: false,
        decrease: true,
        datasets: [{
          label: '',
          color: '#007bff',
          fill: 'start',
          borderWidth: 1.5,
          backgroundColor: colors.success.toRGBA(0.1),
          borderColor: colors.success.toRGBA(),
          data: [] // [9, 3, 3, 9, 9],
        }],
      }, {
        label: 'NEW TICKET',
        color: '#ffb400',
        value: this.$store.state.counterInformation.data.totalNewTicket,
        percentage: '0%',
        increase: true,
        decrease: false,
        datasets: [{
          label: '',
          color: '#ffb400',
          fill: 'start',
          borderWidth: 1.5,
          backgroundColor: colors.warning.toRGBA(0.1),
          borderColor: colors.warning.toRGBA(),
          data: [] // [9, 3, 3, 9, 9],
        }],
      }, {
        label: 'NEW CONFIRMED PAYMENT',
        color: '#ff4169',
        value: this.$store.state.counterInformation.data.totalNewPaymentClientConfirm,
        percentage: '0%',
        increase: false,
        decrease: true,
        datasets: [{
          label: '',
          color: '#ff4169',
          fill: 'start',
          borderWidth: 1.5,
          backgroundColor: colors.salmon.toRGBA(0.1),
          borderColor: colors.salmon.toRGBA(),
          data: []
        }],
      }]
      const storage = localStorage.getItem('admin-type');
      const data = decrypt(storage)
      if (data.includes('subdealer') || data.includes('calibrator') || !this.$store.state.buySale.information.haveChildren) {
        arrayData.splice(3, 1)
      }
      return arrayData;
    },
  },
  data: () => ({
    arrayList: [],
    columns: [
      {key: 'viewed', label: 'Updated', sortable: true, tdClass: 'text-center', thClass: 'text-center',},
      {key: 'orderId', label: 'Number', sortable: true, tdClass: 'text-center', thClass: 'text-center',},
      {key: 'type', label: 'Type', sortable: true, tdClass: 'text-center', thClass: 'text-center',},
      // {key: 'client.fullName', label: 'Customer Name', sortable: true,},
      {key: 'client.username', label: 'Company Name', sortable: true, tdClass: 'text-center', thClass: 'text-center',},
      {key: 'client.country', label: 'Country', sortable: true, tdClass: 'text-center', thClass: 'text-center',},
      {key: 'VIN', label: 'VIN', sortable: true, tdClass: 'text-center', thClass: 'text-center',},
      {key: 'stage', label: 'Stage', sortable: true, tdClass: 'text-center', thClass: 'text-center',},
      {key: 'vehicle', label: 'Vehicle', sortable: true, tdClass: 'text-center', thClass: 'text-center',},
      // {key: 'urgency', label: 'Urgency', sortable: true,},
      {
        key: 'calibrator.fullName',
        label: 'Assigned to',
        sortable: true,
        tdClass: 'text-center',
        thClass: 'text-center',
      },
      {key: 'status', label: 'Status', sortable: true, tdClass: 'text-center', thClass: 'text-center',},
      {key: 'createdAt', label: 'Added', sortable: true, tdClass: 'text-center', thClass: 'text-center',},
      {key: 'actions', label: 'Actions', thClass: 'text-center',}
    ],
    fields: [],
    filters: {
      query: null,
      status: null,
      order: 0,
      can_be_completed: false,
      timer: null
    },
    dataFilter: '',
    pagination: {
      perPage: 20,
      current: 1
    },
    perPage: 20,
    currentPage: 1,
    ORDER_STATUS: ORDER_STATUS(),
    graphicsOrder: [],
    filterCalibrator: false,
    rolSession: null,
    userIdSession: null,
    showModalHelp: false,
    rows: 0,
    customMarked: {
      id: null,
      comment: null
    },
    arrayCustomMarked: {
      ids: [],
      data: [],
    },
  }),
  beforeMount() {
    this.$store.dispatch("counterInformation/loadCounterData")
  },
  async mounted() {
    const storeRol = localStorage.getItem('admin-type')
    const storageId = localStorage.getItem("admin-id")
    this.rolSession = JSON.parse(decrypt(storeRol))
    this.userIdSession = JSON.parse(decrypt(storageId))
    const storageHelp = localStorage.getItem('show-modal-help')
    const rolesClient = [UserRole.SubDealer, UserRole.Dealer, UserRole.Distributor]
    if (userCan(rolesClient)) {
      if (storageHelp === null) {
        localStorage.setItem('show-modal-help', 'true')
      }
    } else {
      await this.getCustomMarked()
    }
    await this.loadData(false, 'mounted')
    this.sockets.subscribe('supportUpdateList', () => {
      this.$store.dispatch("counterInformation/loadCounterData").then()

    });
    this.sockets.subscribe('updateListOrders', (data) => {
      const roles = [UserRole.Calibrator, UserRole.Admin]
      if (userCan(roles)) {
        this.$store.dispatch("counterInformation/loadCounterData").then()
        this.loadData(false, 'notify', false).then()
      } else {
        const containId = this.arrayList.find(x => Number(x.id) === Number(data))
        if (containId) {
          this.$store.dispatch("counterInformation/loadCounterData").then()
          this.loadData(false, 'notify', false).then()
        }
      }
    })
  },
  methods: {
    ...mapMutations(['loaderManager', 'notificationManager']),
    async loadData(type = '', event = '', showLoader = true) {
      clearTimeout(this.filters.timer);
      if (showLoader)
        this.loaderManager(true);
      if (type.toString() === 'calibrator') {
        this.filterCalibrator = event
        this.paginate(1);
      }
      this.getAndSetQueryParams()
      get(
        `${orderSoftware}?page=${this.pagination.current}&limit=${this.pagination.perPage}&status=${this.filters.status}&calibrator=${this.filterCalibrator}&filter=${encodeURIComponent(this.filters.query)}&order=${this.filters.order}&can_be_completed=${this.filters.can_be_completed ? 0 : null}`,
        null, true).then(({data}) => {
        this.rows = data.message.totalRows
        this.arrayList = data.message.orders.map(row => {
          if (row.vehicle !== null && row.vehicle !== '') {
            const ve = JSON.parse(row.vehicle)
            if (Object.keys(ve).length) {
              let vehicle = ''
              vehicle += (ve.brand !== null) ? ve.brand : ''
              vehicle += (ve.model !== null) ? ' / '+ ve.model : ''
              vehicle += (ve.generation!== null) ? ' / '+ve.generation : ''
              vehicle += (ve.engine !== null) ? ' / '+ve.engine : ''
              row.vehicle = vehicle
            } else {
              row.vehicle = ' -- '
            }
          }
          if (row.calibratorIds && row.calibratorIds.length) {
            row.calibratorId = row.calibratorIds.pop();
            row.calibrator = data.message.calibrators.find(el => el.id === row.calibratorId)
          } else {
            if (!row.descriptionObject.item.length && row.has_tuned_files) {
              row.calibrator = {fullName: 'File Server'}
            }
            row.calibratorId = null;
          }
          row.urgency = getUrgencyName(row.urgency)
          const clientCountry = this.$store.getters["geo/country"](row.client.countryId);
          row.client.country = clientCountry ? clientCountry.alpha3Code : ''
          row.client.fullName = (row.client.fullName.length > 20) ? row.client.fullName.substr(0, 20) + "..." : row.client.fullName
          row.viewed = this.getOrdersViewed(row, data.message.ordersTransferToMe)
          row.marked = this.getIsMarked(row.id)
          return {...row,}
        })
        this.loaderManager(false)
        this.$refs.paginator.currentPage = this.pagination.current;
      });
    },
    canBeCompleted(e) {
      this.filters.can_be_completed = e;
      this.paginate(1);
    },
    async paginate(page) {
      this.$router.push({
        name: 'dashboard', query: {
          page: page,
          perPage: this.pagination.perPage,
          status: this.filters.status,
          order: this.filters.order,
          can_be_completed: this.filters.can_be_completed,
        }
      }).then().catch()
    },
    getAndSetQueryParams() {
      if (this.$route.query.page) {
        this.pagination.current = this.$route.query.page ? Number(this.$route.query.page) : this.pagination.current
        this.pagination.perPage = this.$route.query.perPage ? Number(this.$route.query.perPage) : this.pagination.perPage
        this.filters.status = this.$route.query.status ? this.$route.query.status : this.filters.status
        this.filters.order = this.$route.query.order ? this.$route.query.order : this.filters.order
        this.filters.can_be_completed = this.$route.query.can_be_completed ? this.$route.query.can_be_completed.toString() === 'true' : this.filters.can_be_completed
      }
      this.filters.query = (this.filters.query !== '' && this.filters.query !== null) ? this.filters.query.replace(/[^a-zA-Z0-9 ]/g, "") : null
    },
    getOrdersViewed(row, ordersTransferToMe) {
      try {
        if (row.viewed) {
          row.viewed = JSON.parse(row.viewed);
          return Number(row.viewed[this.rolSession])
        } else
          return 0
      } catch (e) {
        console.error("error: ", e)
        return 0;
      }
    },
    getAmount(type) {
      return 0;
    },
    getColor(status) {
      const obj = this.ORDER_STATUS.find(x => x.value === status)
      return obj ? obj.color : 'black'
    },
    viewOrder(id, openchat = false) {
      const cryp = encrypt(JSON.stringify({id: id}))
      this.$router.push({
        name: 'order-detail',
        params: {id: cryp},
        query: openchat ? {'open-chat': ''} : null
      });
    },
    keySearch(e) {
      this.pagination.current = 1;
      this.$route.query.page = 1;
      if (e.which == 13) {
        clearTimeout(this.filters.timer)
        this.loadData();
        return
      }
      if (this.filters.timer) {
        clearTimeout(this.filters.timer);
        this.filters.timer = null;
      }
      this.filters.timer = setTimeout(() => {
        this.loadData()
      }, 500);
    },
    async getCustomMarked() {
      const {data} = await get("custom-marked/index", null, true)
      this.arrayCustomMarked.data = data
      this.arrayCustomMarked.ids = data.map(x => x.order_id)
    },
    async showCustomMarkedModal(item) {
      this.customMarked.id = item.id
      this.$bvModal.show('modal-comment-software')
    },
    async createCustomMarked() {
      if (!!this.customMarked.id) {
        await post("custom-marked/create", this.customMarked, true)
        this.$bvModal.hide('modal-comment-software')
        await this.getCustomMarked()
        await this.loadData(false, 'mounted')
        this.showAlertNotification('Marker added')
        this.customMarked.id = null
        this.customMarked.comment = null
      }
    },
    deleteCustomMarked(item) {
      const isOwner = this.arrayCustomMarked.data.find(x => x.order_id === item.id && x.owner_id === this.userIdSession)
      if (!!isOwner) {
        this.$swal({
          showConfirmButton: true,
          showCancelButton: true,
          title: 'Are you sure you want to remove the mark?',
          confirmButtonColor: "#d14343",
          cancelButtonColor: "#000000",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        }).then(async res => {
          if (res.isConfirmed) {
            await post("custom-marked/delete-marked", {id: item.id}, true)
            await this.getCustomMarked()
            await this.loadData(false, 'mounted')
            this.showAlertNotification('Marker removed')
          }
        });
      }
    },
    getComment(id) {
      let arrayComment = []
      this.arrayCustomMarked.data.filter(x => x.order_id === id).forEach(x => {
        if (x) {
          const ele = (x.comment) ? `${x.owner_name} - ${x.comment}` : x.owner_name
          arrayComment.push(ele + "\n")
        }
      })
      return arrayComment.join(" ")
    },
    getIsMarked(rowId) {
      return this.arrayCustomMarked.ids.includes(rowId)
    }
  },
  watch: {
    $route() {
      this.loadData()
    }
  },
  destroyed() {
    this.sockets.unsubscribe('updateListOrders')
    this.sockets.unsubscribe('supportUpdateList')
  }
});
