<template>
  <b-card :class="['stats-small', computedVariationClass, 'card', 'card-small']">
    <b-card-body :class="[computedBodyClass]">
      <div :class="['d-flex', computedInnerWrapperClass]">
        <div :class="['stats-small__data', computedDataFieldClass]">
          <span  :class="['stats-small__label', 'text-uppercase', computedLabelClass]"
                :style="` font-weight: 500; font-size: medium;`">{{ label }}</span>
          <h6 :class="['stats-small__value', 'count', computedValueClass]" :style="`color: ${color}; font-weight:500`">{{ value }}</h6>
        </div>
      </div>
<!--      <canvas :height="computedCanvasHeight" ref="canvas" :class="[computedChartId]"></canvas>-->
    </b-card-body>
  </b-card>
</template>

<script>
import Chart from '../../utils/chart';

export default {
  name: 'd-small-stats',
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: 'Label',
    },
    value: {
      type: [Number, String],
      default: 0,
    },
    percentage: {
      type: [Number, String],
      default: 0,
    },
    increase: {
      type: Boolean,
      default: true,
    },
    decrease: {
      type: Boolean,
      default: false,
    },
    chartOptions: {
      type: Object,
      default() {
        return {};
      },
    },
    chartData: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    chartConfig: {
      type: Object,
    },
    variation: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: null
    }
  },
  computed: {
    computedChartId() {
      return this.id || `stats-small-2`;
    },
    computedPercentageModifier() {
      return this.increase ? 'increase' : 'decrease';
    },
    computedVariationClass() {
      return this.variation ? `stats-small--${this.variation}` : null;
    },
    computedBodyClass() {
      if (this.variation === '1') {
        return 'mt-0 mb-0 d-flex';
      }

      return 'px-0 pb-0';
    },
    computedInnerWrapperClass() {
      if (this.variation === '1') {
        return 'flex-column m-auto';
      }

      return 'px-3';
    },
    computedDataFieldClass() {
      if (this.variation === '1') {
        return 'text-center';
      }

      return null;
    },
    computedLabelClass() {
      if (this.variation === '1') {
        return null;
      }
      return 'mb-1';
    },
    computedValueClass() {
      if (this.variation === '1') {
        return 'my-2';
      }
      return 'm-0';
    },
    computedInnerDataFieldClass() {
      if (this.variation === '1') {
        return null;
      }

      return 'text-right align-items-center';
    },
    computedCanvasHeight() {
      if (this.variation === '1') {
        return 120;
      }

      return 60;
    },
  },
};
</script>

<style scoped lang="scss">
/* IE11 height and chart positioning fix */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .card-body {
    min-height: 140px;
    position: relative;
  }

  .stats-small canvas {
    left: 0;
  }

  .stats-small--1 .stats-small__data {
    align-items: center !important;
    padding-top: 20px;
  }
}
</style>
